/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import { graphql, navigate } from 'gatsby';
import { useState, useEffect } from 'react';
import { object } from 'prop-types';
import { Transition } from 'react-transition-group';
import { Wrap } from '../components/sections-wrap';
import { Spinner } from '../components/spinner';
import { LinearProgress } from '../components/common/linear-progess-bar';
import {
  Header, Pagination, Question, Faqs, Children,
} from '../components/alimony-calculator';
import * as styles from './templates.module.scss';
import {
  replaceSpecialCharacterText, convertToCurrencyValue, convertCurrencyToValue,
  setLocalStorageItem, classNames,
} from '../helpers';
import {
  coloradoRedirectToTermsAndConditionIfNecessary,
  texasRedirectToTermsAndConditionIfNecessary,
  attorneyPayRedirectToTermsAndConditionIfNecessary,
  isSomeoneElegibleTexas,
} from '../functions-calculator';
import { ResetButton } from '../components/common/reset-button';
import {
  isNumberInValidRange, getLocalStorageItem, saveStateInHistory, navigateWithState,
} from '../helpers/misc';
import { constants } from '../config';

const initializeState = (calculatorType, state, stateType) => {
  switch (stateType) {
    case 'map':
    {
      if (typeof window !== 'undefined' && localStorage[calculatorType]) {
        if (JSON.parse(localStorage[calculatorType])[state]) {
          return (new Map(JSON.parse(localStorage[calculatorType])[state]));
        }
      }
      return new Map();
    }
    case 'number':
    {
      if (typeof window !== 'undefined' && localStorage[calculatorType]) {
        if (JSON.parse(localStorage[calculatorType])[state]) {
          return JSON.parse(localStorage[calculatorType])[state];
        }
      }
      return 0;
    }
    default:
    {
      if (typeof window !== 'undefined' && localStorage[calculatorType]) {
        if (JSON.parse(localStorage[calculatorType])[state]) {
          return JSON.parse(localStorage[calculatorType])[state];
        }
      }
      return '';
    }
  }
};

const INITIAL_CHILDREN_STATE = {
  hasChildren: '',
  children: [],
};

const INITIAL_DATA = {
  answers: {},
  currentSection: 0,
  currentQuestion: 0,
  lastQuestionVisited: 0,
  currentPagination: 0,
};

function AlimonyCalculatorTemplate({ data: dataQueried, pageContext, location }) {
  const { headerOptions } = pageContext;
  const calculatorTypeData = dataQueried.contentfulCalculatorType;
  const { name, calculatorId, calculatorUri } = pageContext;
  const calculatorType = calculatorTypeData.type;
  const maxSections = calculatorTypeData.sections.length;
  const [data, setData] = useState(INITIAL_DATA);
  const [isValidChildren, setIsValidChildren] = useState(false);
  const [value, setValue] = useState('');
  const [isLoaded, setIsLoaded] = useState(true);
  const [arrowNavigateLoaded, setArrowNavigateLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  let { sections } = calculatorTypeData;
  const { name: calculatorTypeName } = calculatorTypeData;

  sections = sections?.sort((a, b) => (a.sectionOrder - b.sectionOrder));
  const {
    currentQuestion,
    currentSection,
    currentPagination,
    lastQuestionVisited,
    answers,
  } = data;

  const sectionData = sections[currentSection];
  const { sectionName } = sectionData;
  let questions = pageContext.questions.filter(
    (question) => question.section?.filter(
      (section) => section.sectionName === sectionName,
    ).length,
  );
  questions = questions?.sort((a, b) => (a.qOrder - b.qOrder));
  const questionsPagination = questions.filter((question) => !question.conditionalOnQuestion);
  const questionsInCurrentSection = questions.length;
  const questionData = questions[currentQuestion];
  const {
    id, faQs, questionTextLong, textToInsert, questionType,
    insertAnswerTextQuestionId: questionsToGetAnswers, group,
    isMandatory, defaultText, alimonyVariableName, minValue, maxValue,

  } = questionData || {};
  let quesText = questionTextLong?.questionTextLong;

  quesText = !!answers.length && replaceSpecialCharacterText(
    quesText,
    textToInsert,
    questionsToGetAnswers,
    answers,
    defaultText,
  );

  const saveUsefulDataInStorage = (val = value, allAnswers = answers) => {
    if (typeof window !== 'undefined' && answers.size) {
      localStorage[calculatorTypeName] = JSON.stringify(
        {
          answers: Array.from(allAnswers.entries()),
          currentQuestion,
          currentSection,
          currentPagination,
          lastQuestionVisited,
          val,
        },
      );
    }
  };

  // Next question available could be in the same section or in the next one and it could be
  // conditional or nonConditional, pagination just advance when it is a nonConditional.
  // increaseQuestions represents how many question we must advance in the array,
  // if we advance more than the array length, we need to advance to the next section.
  const findNextQuestionAvailable = (newAnswers) => {
    let increaseQuestions = 1;
    let isConditionalQuestion = false;
    let isNonConditionalQuestion = false;

    while (currentQuestion + increaseQuestions < questionsInCurrentSection
      && !isNonConditionalQuestion && !isConditionalQuestion) {
      const nextQuestion = questions[currentQuestion + increaseQuestions];

      if (nextQuestion.conditionalOnQuestion) {
        const conditionalQuestionValue = newAnswers.get(
          nextQuestion.conditionalOnQuestion.id,
        )?.value;
        if (nextQuestion.alimonyVariableName === 'isDeductible') {
          const dateConditionalQuestion = new Date(conditionalQuestionValue);
          const dateNextQuestion = new Date(nextQuestion.answerRequired);
          if (dateConditionalQuestion.getTime() <= dateNextQuestion.getTime()) {
            isConditionalQuestion = true;
          } else {
            increaseQuestions += 1;
          }
        } else if
        (conditionalQuestionValue?.toLowerCase() === nextQuestion.answerRequired?.toLowerCase()) {
          isConditionalQuestion = true;
        } else {
          increaseQuestions += 1;
        }
      } else {
        isNonConditionalQuestion = true;
      }
    }
    return ({ isNonConditionalQuestion, isConditionalQuestion, increaseQuestions });
  };

  const onNextPage = () => {
    saveStateInHistory({ ...data, value });
    const newData = structuredClone(data);
    const prevQuestionValue = data.answers.get(id)?.value;
    if (prevQuestionValue && prevQuestionValue !== value) {
      // Delete answers saved of the conditional question that depends on this one
      const questionIdsToDelete = questions
        .filter((question) => question.conditionalOnQuestion?.id === id)
        .map((question) => question.id);
      questionIdsToDelete.forEach((questionId) => newData.answers?.delete(questionId));
    }
    const newAnswers = newData?.answers.set(id, {
      question: quesText, value, section: sectionName, alimonyVariableName,
    });
    newData.answers = newAnswers;
    const {
      isNonConditionalQuestion, isConditionalQuestion,
      increaseQuestions,
    } = findNextQuestionAvailable(newAnswers);
    const questionAvailableInCurrentSection = isConditionalQuestion
    || isNonConditionalQuestion;
    const moreSections = currentSection + 1 < calculatorTypeData.sections.length;

    if (questionAvailableInCurrentSection) {
      setIsVisible(false);
      if (isNonConditionalQuestion) {
        newData.currentPagination += 1;
      }
      newData.currentQuestion += increaseQuestions;
      setData(newData);
      navigateWithState(calculatorUri, newData);
    } else if (moreSections) {
      // To not make users go till the end when we know both aren't elegible to receive alimony.
      if (calculatorId === constants.calculatorsIds.texasAlimonyCalculator.id
        && sectionName === 'Disability'
        && !isSomeoneElegibleTexas(newAnswers)) {
        setLocalStorageItem(
          constants.calculatorsIds.texasAlimonyCalculator.calculatorFinishedKey,
          true,
        );
        navigate(`/texas/alimony-payment?type=${calculatorType}`);
      } else {
        setIsVisible(false);
        newData.currentSection += 1;
        newData.currentPagination = 0;
        newData.lastQuestionVisited = 0;
        newData.currentQuestion = 0;
        setData(newData);
        navigateWithState(calculatorUri, newData);
      }
    } else {
      saveUsefulDataInStorage(value, newData.answers);
      if (calculatorId === constants.calculatorsIds.coloradoAlimonyCalculator.id) {
        setLocalStorageItem(
          constants.calculatorsIds.coloradoAlimonyCalculator.calculatorFinishedKey,
          true,
        );
        navigate(`/colorado/alimony-payment?type=${calculatorType}`);
      } else if (calculatorId === constants.calculatorsIds.texasAlimonyCalculator.id) {
        setLocalStorageItem(
          constants.calculatorsIds.texasAlimonyCalculator.calculatorFinishedKey,
          true,
        );
        navigate(`/texas/alimony-payment?type=${calculatorType}`);
      } else if (calculatorId === constants.calculatorsIds.attorneyPayCalculator.id) {
        setLocalStorageItem(
          constants.calculatorsIds.attorneyPayCalculator.calculatorFinishedKey,
          true,
        );
        navigate('/about/careers/attorney-pay/payment');
      }
    }
  };

  // Return a previous question available taking an array of question and the index of the current
  // question into account. The question before of the current could be a conditionalQuestion that
  // doesn't satisfy its rendering condition so we need to keep moving back.
  const findPrevQuestionAvailable = (Ques, currentIndex, newAnswers) => {
    let decreaseQuestions = 1;
    let findQuestion = false;

    while (currentIndex - decreaseQuestions > 0 && !findQuestion) {
      const prevQuestion = Ques[currentIndex - decreaseQuestions];

      if (prevQuestion.conditionalOnQuestion) {
        const conditionalQuestion = newAnswers.get(prevQuestion.conditionalOnQuestion.id);
        if (conditionalQuestion) {
          const conditionalQuestionValue = conditionalQuestion?.value;
          if (prevQuestion.alimonyVariableName === 'isDeductible') {
            const dateConditionalQuestion = new Date(conditionalQuestionValue);
            const datePrevQuestion = new Date(prevQuestion.answerRequired);
            if (dateConditionalQuestion.getTime() <= datePrevQuestion.getTime()) {
              findQuestion = true;
            } else {
              decreaseQuestions += 1;
            }
          } else if (conditionalQuestionValue === prevQuestion.answerRequired) {
            findQuestion = true;
          } else {
            decreaseQuestions += 1;
          }
        } else {
          decreaseQuestions += 1;
        }
      } else {
        findQuestion = true;
      }
    }
    return currentIndex - decreaseQuestions;
  };

  const findTopConditionalQuestion = (idToFind) => {
    let decreaseQuestions = 1;
    let questionFound = false;

    while (currentQuestion - decreaseQuestions > 0 && !questionFound) {
      const prevQuestion = questions[currentQuestion - decreaseQuestions];

      if (prevQuestion.id === idToFind) {
        questionFound = true;
      } else {
        decreaseQuestions += 1;
      }
    }

    return currentQuestion - decreaseQuestions;
  };

  const getPrevSectionQuestions = () => {
    const prevSection = calculatorTypeData.sections[currentSection - 1];
    const prevSectionName = prevSection.sectionName;
    let prevQuestions = pageContext.questions.filter(
      (question) => question.section?.filter(
        (section) => section.sectionName === prevSectionName,
      ).length,
    );
    prevQuestions = prevQuestions?.sort((a, b) => (a.qOrder - b.qOrder));
    return prevQuestions;
  };

  // Move to a previous question available when users clicks on the prev button,
  // due to the pre-condition rule, users just move to a previous sections when the current question
  // is the first question of the section. if section decreases, pagination and lastquestion visited
  // changes to the length value of the nonContional questions array of the section.
  // Pagination will decrease just if the current question is a nonConditional
  // Pre-condition; Each section starts with a nonConditional question.
  const onPrevPage = () => {
    setIsVisible(false);
    saveStateInHistory({ ...data, value });
    const newData = structuredClone(data);
    const newAnswers = newData?.answers.set(id, {
      question: quesText, value, section: sectionName, alimonyVariableName,
    });
    newData.answers = newAnswers;
    const isTheCurrentQuestionConditional = questions[currentQuestion].conditionalOnQuestion;
    const paginationWillDecrease = !isTheCurrentQuestionConditional;
    const sectionWillDecrease = currentQuestion === 0;

    if (sectionWillDecrease) {
      newData.currentSection -= 1;
      const prevQuestions = getPrevSectionQuestions();
      const prevQuestionsPagination = prevQuestions
        .filter((question) => !question.conditionalOnQuestion);
      newData.currentPagination = prevQuestionsPagination.length - 1;
      newData.lastQuestionVisited = prevQuestionsPagination.length - 1;
      const indexPrevQuestion = findPrevQuestionAvailable(
        prevQuestions,
        prevQuestions.length,
        newAnswers,
      );
      newData.currentQuestion = indexPrevQuestion;
    } else {
      const index = findPrevQuestionAvailable(questions, currentQuestion, newAnswers);
      if (paginationWillDecrease) {
        newData.currentPagination -= 1;
      }
      newData.currentQuestion = index;
    }
    setData(newData);
    if (document !== undefined) {
      document.activeElement.blur();
    }
    navigateWithState(calculatorUri, newData);
  };

  const changeAnswer = () => {
    const question = questions[currentQuestion];
    const topQuestionId = question.conditionalOnQuestion.id;
    const newData = structuredClone(data);
    newData.currentQuestion = findTopConditionalQuestion(topQuestionId);
    setData(newData);
  };

  const toPage = (page) => {
    setIsVisible(false);
    saveStateInHistory({ ...data, value });
    const itHasValue = !!answers.get(id)?.value;
    // TODO; Add is mandatory condition
    const goBackWithFieldEmptyWhenRequired = page < currentPagination + 1 && !value;
    const newData = structuredClone(data);
    const newAnswers = answers.set(id, {
      question: quesText,
      value,
      section: sectionName,
      alimonyVariableName,
    });
    newData.answers = newAnswers;
    if (goBackWithFieldEmptyWhenRequired && itHasValue) {
      newData.lastQuestionVisited = page;
    }
    let pagination = 0;
    let question = 0;
    while (pagination !== page || questions[question].conditionalOnQuestion) {
      const questionIsNotConditional = !questions[currentQuestion].conditionalOnQuestion;
      if (questionIsNotConditional) {
        pagination += 1;
      }
      question += 1;
    }
    newData.currentPagination = page;
    newData.currentQuestion = question;
    setData(newData);
    navigateWithState(calculatorUri, newData);
  };

  // Don't delete the variable name.
  const setValueRadioButton = (name, newValue) => {
    setValue(newValue);
  };

  const setValueCurrency = (newValue) => {
    setValue(convertCurrencyToValue(newValue));
  };

  const handleSetValue = (qt) => {
    if (qt === 'radio') {
      return setValueRadioButton;
    }
    if (qt === 'currency') {
      return setValueCurrency;
    }
    return setValue;
  };

  const settingInitialVariables = async () => {
    const newData = structuredClone(data);
    const initialCurrentPagination = await initializeState(calculatorTypeName, 'currentPagination', 'number');
    const initialCurrentSection = await initializeState(calculatorTypeName, 'currentSection', 'number');
    const initialCurrentQuestion = await initializeState(calculatorTypeName, 'currentQuestion', 'number');
    const initialLastQuestionVisited = await initializeState(calculatorTypeName, 'lastQuestionVisited', 'number');
    const initialAnswers = await initializeState(calculatorTypeName, 'answers', 'map');
    newData.currentPagination = initialCurrentPagination;
    newData.currentSection = initialCurrentSection;
    newData.lastQuestionVisited = initialLastQuestionVisited;
    newData.currentQuestion = initialCurrentQuestion;
    newData.answers = initialAnswers;
    setData(newData);
    setIsLoaded(false);
  };

  useEffect(() => {
    let wasRedirected = false;
    if (calculatorId === constants.calculatorsIds.coloradoAlimonyCalculator.id) {
      wasRedirected = coloradoRedirectToTermsAndConditionIfNecessary(navigate);
    } else if (calculatorId === constants.calculatorsIds.texasAlimonyCalculator.id) {
      wasRedirected = texasRedirectToTermsAndConditionIfNecessary(navigate);
    } else if (calculatorId === constants.calculatorsIds.attorneyPayCalculator.id) {
      wasRedirected = attorneyPayRedirectToTermsAndConditionIfNecessary(navigate);
    }
    if (!wasRedirected) {
      setLocalStorageItem('currentCalculator', calculatorTypeName);
      settingInitialVariables();
    }
  }, []);

  useEffect(() => {
    if (!history.state?.value) {
      const newValue = value || '';
      saveStateInHistory({ value: newValue });
      saveUsefulDataInStorage(newValue);
    }
  }, [location.state]);

  // Define a function to be executed when the user navigates back
  const onNavigateByArrows = () => {
    if (history.state?.answers) {
      setArrowNavigateLoaded(true);
      const currentState = history.state;
      const newData = structuredClone(data);
      newData.currentPagination = currentState.currentPagination;
      newData.currentSection = currentState.currentSection;
      newData.lastQuestionVisited = currentState.lastQuestionVisited;
      newData.currentQuestion = currentState.currentQuestion;
      newData.answers = structuredClone(currentState.answers);
      setData(newData);
      const newValue = currentState.value;
      setValue(newValue);
    }
  };

  //  Attach an event listener to the onpopstate event (onClick on browser arrows)
  if (typeof window !== 'undefined') {
    window.onpopstate = (event) => {
      if (event.state !== null) {
        onNavigateByArrows();
      }
    };
  }

  useEffect(() => {
    if (questions[currentQuestion] && !isLoaded && !arrowNavigateLoaded) {
      const questionValue = answers.get(questions[currentQuestion].id)?.value;
      let val = '';
      if (questionValue) {
        setValue(questionValue);
        val = questionValue;
      } else if (questionType === 'number') {
        if (minValue) {
          setValue(minValue);
          val = minValue;
        } else {
          setValue(0);
          val = 0;
        }
      } else {
        setValue('');
      }
      saveUsefulDataInStorage(val);
    }
    if (arrowNavigateLoaded) saveUsefulDataInStorage(value);
    setArrowNavigateLoaded(false);
  }, [currentQuestion, currentSection, isLoaded]);

  useEffect(() => {
    if (lastQuestionVisited < currentPagination) {
      const newData = structuredClone(data);
      newData.lastQuestionVisited = currentPagination;
      setData(newData);
    }
  }, [currentPagination]);

  const isQuestionTypeNumberError = questionType === 'number' && value !== '' && !isNumberInValidRange(value, minValue, maxValue);

  const nextAllowed = () => {
    if (group === 'children') return isValidChildren;
    if (questionType === 'number') return !isQuestionTypeNumberError;
    return (!!value || !isMandatory);
  };

  useEffect(() => {
    setIsVisible(true);
  }, [isVisible]);

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      if (nextAllowed()) {
        onNextPage();
      }
    }
  };
  const showStartOver = () => getLocalStorageItem(calculatorTypeName) !== null;

  // Each dependecy used must be added
  // otherwise the event always execute with the initial value of it
  useEffect(() => {
    const handleKeyDown = (e) => onEnterPress(e);
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [group, isValidChildren, value, isMandatory, onNextPage, nextAllowed]);

  return (
    <div className={styles.pageStyles}>
      <Wrap>
        {isLoaded ? (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        ) : (
          <div className={styles.calculatorContainer}>
            <Header
              menu
              title={name}
              headerOptions={headerOptions}
              calculatorType={calculatorType}
              calculatorId={calculatorId}
            />
            {isVisible && (
            <Transition timeout={500} in appear>
              {(status) => (
                <div className={classNames([styles.box, styles[status]])}>
                  {group === 'children' ? (
                    <Children
                      id={id}
                      sectionName={sectionName}
                      name={answers?.get(questionsToGetAnswers[0]?.id)?.value}
                      setValue={setValue}
                      value={typeof value === 'object' ? value : INITIAL_CHILDREN_STATE}
                      setIsValidChildren={setIsValidChildren}
                      alimonyVariableName={alimonyVariableName}
                      onNextPage={onNextPage}
                      nextAllowed={group === 'children' ? isValidChildren : (!!value || !isMandatory)}
                    />
                  ) : (
                    <Question
                      id={id}
                      question={questionData}
                      value={questionType === 'currency' ? convertToCurrencyValue(value) : value}
                      setValue={handleSetValue(questionType)}
                      sectionName={sectionName}
                      answers={answers}
                      onNextPage={onNextPage}
                      calculatorType={calculatorType}
                      calculatorTypeName={calculatorTypeName}
                      changeAnswer={changeAnswer}
                      isQuestionTypeNumberError={isQuestionTypeNumberError}
                    />
                  )}
                </div>
              )}
            </Transition>
            )}
            <div className={styles.paginationContainer}>
              <Pagination
                meta={{
                  totalPages: questionsPagination.length,
                  pageIndex: currentPagination + 1,
                  question: currentQuestion + 1,
                  section: currentSection + 1,
                }}
                lastQuestion={lastQuestionVisited + 1}
                className={styles.pagination}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                toPage={toPage}
                nextAllowed={nextAllowed()}
              />
              <LinearProgress
                label="Sections"
                currentStep={currentSection + 1}
                steps={maxSections}
                className={styles.colorBar}
                showContainer
              />
              {showStartOver() && (
                <ResetButton
                  calculatorType={calculatorTypeName}
                />
              )}
            </div>

            {!!faQs?.length && (
              <Faqs faqs={faQs} />
            )}
          </div>
        )}
      </Wrap>
    </div>
  );
}

export const query = graphql`
  query ($id: String){
    contentfulCalculatorType(id: { eq: $id }) {
      id
      name
      type
      titleSchema
      descriptionSchema
      sections {
        id
        sectionName
        sectionOrder
      }
    }
  }
`;

AlimonyCalculatorTemplate.propTypes = {
  data: object,
  location: object,
  pageContext: object,
};

AlimonyCalculatorTemplate.defaultProps = {
  data: {},
  location: {},
  pageContext: {},
};

export default AlimonyCalculatorTemplate;
export { Head } from '../components/seo/seo';
